


































































import LinktreeLink from "@/models/LinktreeLink";
import LinkItem from "@/components/LinkItem.vue";
import FrittenService from "@/services/FrittenService";
import { Component, Vue, Watch } from "vue-property-decorator";
import { uuid } from "vue-uuid";

var authUrl = process.env.VUE_APP_FRITTEN_AUTH; // url of 'auth.php' from php-api-auth
var clientId = "default"; // client id as defined in php-api-auth
var audience = "api.php"; // api audience as defined in php-api-auth

@Component({
    components: {
        LinkItem
    }
})
export default class Admin extends Vue {
    Links = new Array<LinktreeLink>();
    accessToken: string | null = null;

    get HasToken(): boolean {
        return this.accessToken != null;
    }

    types = [
        { text: "Link", value: 0 },
        { text: "Überschrift", value: 1 }
    ];

    new_entry_dialog = false;
    new_entry_type = 0;
    new_entry_text = "";
    new_entry_url = "";

    @Watch("new_entry_dialog")
    dialogVisible(visible: boolean): void {
        if (visible) {
            this.new_entry_type = 0;
            this.new_entry_text = "";
            this.new_entry_url = "";
        }
    }

    mounted(): void {
        var match = RegExp("[#&]access_token=([^&]*)").exec(window.location.hash);
        this.accessToken = match && decodeURIComponent(match[1].replace(/\+/g, " "));
        if (!this.accessToken) {
            var newUrl = authUrl + "?audience=" + audience + "&response_type=token&client_id=" + clientId + "&redirect_uri=" + document.location.href;
            document.location.assign(newUrl);
        } else {
            FrittenService.SetBearerToken(this.accessToken);
            FrittenService.GetLinks().then(links => {
                this.Links = links.records;
            });
            //alert("hab's");
            // document.location.hash = "";
            // var req = new XMLHttpRequest();
            // req.onreadystatechange = function() {
            //     if (req.readyState == 4) {
            //         console.log(req.responseText);
            //         //document.getElementById('output').innerHTML = JSON.stringify(JSON.parse(req.responseText), undefined, 4);
            //     }
            // };
            // //url = 'api.php/records/posts?join=categories&join=tags&join=comments&filter=id,eq,1';
            // var url = "api.php/records/links";
            // req.open("GET", url, true);
            // req.setRequestHeader("X-Authorization", "Bearer " + this.accessToken);
            // req.send();
        }
    }

    add_new_entry(): void {
        this.new_entry_dialog = false;
        if (this.new_entry_url || this.new_entry_type > 0) {
            var link: LinktreeLink = {
                id: uuid.v1(),
                type: this.new_entry_type,
                text: this.new_entry_text,
                url: this.new_entry_url,
                ord: this.Links.length
            };
            FrittenService.CreateLink(link).then(() => {
                FrittenService.GetLinks().then(links => {
                    this.Links = links.records;
                });
            });
        }
    }

    linkAction(action: string, link: LinktreeLink): void {
        if (action == "delete") {
            //alert("delete: " + link.id);
            FrittenService.DeleteLink(link).then(() => {
                FrittenService.GetLinks().then(links => {
                    this.Links = links.records;
                });
            });
        } else if (action == "up") {
            let index = this.Links.indexOf(link);
            if (index != 0) {
                let above = this.Links[index - 1];
                link.ord = index - 1;
                above.ord = index;
                let promise1 = FrittenService.UpdateLink(link);
                let promise2 = FrittenService.UpdateLink(above);
                Promise.all([promise1, promise2]).then(() => {
                    FrittenService.GetLinks().then(links => {
                        this.Links = links.records;
                    });
                });
            }
        } else if (action == "down") {
            var index = this.Links.indexOf(link);
            if (index < this.Links.length - 1) {
                var below = this.Links[index + 1];
                link.ord = index + 1;
                below.ord = index;
                var promise1 = FrittenService.UpdateLink(link);
                var promise2 = FrittenService.UpdateLink(below);
                Promise.all([promise1, promise2]).then(() => {
                    FrittenService.GetLinks().then(links => {
                        this.Links = links.records;
                    });
                });
            } else {
                alert("not " + index + "/" + this.Links.length);
            }
        } else {
            alert("not supported: " + action);
        }
    }
}

// window.onload = function() {
//     //setTimeout(function() {
//     var match = RegExp("[#&]access_token=([^&]*)").exec(window.location.hash);
//     var accessToken = match && decodeURIComponent(match[1].replace(/\+/g, " "));
//     if (!accessToken) {
//         var newUrl = authUrl + "?audience=" + audience + "&response_type=token&client_id=" + clientId + "&redirect_uri=" + document.location.href;
//         document.location.assign(newUrl);
//     } else {
//         document.location.hash = "";
//         var req = new XMLHttpRequest();
//         req.onreadystatechange = function() {
//             if (req.readyState == 4) {
//                 console.log(req.responseText);
//                 //document.getElementById('output').innerHTML = JSON.stringify(JSON.parse(req.responseText), undefined, 4);
//             }
//         };
//         //url = 'api.php/records/posts?join=categories&join=tags&join=comments&filter=id,eq,1';
//         var url = "api.php/records/links";
//         req.open("GET", url, true);
//         req.setRequestHeader("X-Authorization", "Bearer " + accessToken);
//         req.send();
//     }
//     //}, 3000);
// };
